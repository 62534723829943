import { ref, defineComponent } from "vue";
export default defineComponent({
    name: "UiDropdown",
    setup() {
        const isOpen = ref(false);
        return {
            isOpen,
        };
    },
});
