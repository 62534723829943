import { render } from "./modal.vue?vue&type=template&id=013247bd"
import script from "./modal.vue?vue&type=script&lang=ts"
export * from "./modal.vue?vue&type=script&lang=ts"

import "./modal.vue?vue&type=style&index=0&id=013247bd&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "013247bd"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('013247bd', script)) {
    api.reload('013247bd', script)
  }
  
  module.hot.accept("./modal.vue?vue&type=template&id=013247bd", () => {
    api.rerender('013247bd', render)
  })

}

script.__file = "resources/js/components/ui/modal.vue"

export default script