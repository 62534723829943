import { render } from "./dropdown.vue?vue&type=template&id=136a8a31"
import script from "./dropdown.vue?vue&type=script&lang=ts"
export * from "./dropdown.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "136a8a31"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('136a8a31', script)) {
    api.reload('136a8a31', script)
  }
  
  module.hot.accept("./dropdown.vue?vue&type=template&id=136a8a31", () => {
    api.rerender('136a8a31', render)
  })

}

script.__file = "resources/js/components/ui/dropdown.vue"

export default script