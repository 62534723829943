import { render } from "./layout-bg-switch.vue?vue&type=template&id=82152eb8"
import script from "./layout-bg-switch.vue?vue&type=script&lang=ts"
export * from "./layout-bg-switch.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "82152eb8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('82152eb8', script)) {
    api.reload('82152eb8', script)
  }
  
  module.hot.accept("./layout-bg-switch.vue?vue&type=template&id=82152eb8", () => {
    api.rerender('82152eb8', render)
  })

}

script.__file = "resources/js/components/layout-bg-switch.vue"

export default script