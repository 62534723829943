import { ref, watch } from "vue";
const isMenuOpen = ref(false);
const useNavigation = () => {
    const toggleMenu = () => {
        isMenuOpen.value = !isMenuOpen.value;
    };
    watch(isMenuOpen, () => {
        if (isMenuOpen.value) {
            document.body.classList.add("menu-is-open");
            return;
        }
        document.body.classList.remove("menu-is-open");
    });
    return {
        isMenuOpen,
        toggleMenu,
    };
};
export default useNavigation;
