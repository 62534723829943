import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { ExpoScaleEase } from "gsap/EasePack";
import { gsap } from "gsap";
import useIntersectionObserver from "@/composables/intersection-observer";
gsap.registerPlugin(ExpoScaleEase);
export default defineComponent({
    name: "UiIntersect",
    props: {
        threshold: {
            type: Number,
            default: 0.4,
        },
        duration: {
            type: Number,
            default: 1.2,
        },
        delay: {
            type: Number,
            default: 0,
        },
        startingY: {
            type: Number,
            default: 50,
        },
    },
    setup(props) {
        const element = ref();
        const { intersectionObserver } = useIntersectionObserver();
        const options = {
            threshold: props.threshold,
        };
        onMounted(() => {
            if (!element.value)
                return;
            const timeline = gsap.timeline({
                paused: true,
            });
            timeline.to(element.value, {
                autoAlpha: 1,
                duration: props.duration,
                ease: "expo.out",
                delay: props.delay,
                y: 0,
            });
            intersectionObserver.observe(element.value, options);
            intersectionObserver.addEnterCallback(element.value, () => {
                timeline.play();
            });
            intersectionObserver.addExitCallback(element.value, () => {
                timeline.reverse();
            });
        });
        onUnmounted(() => {
            if (!element.value)
                return;
            intersectionObserver.unobserve(element.value, options);
        });
        return {
            element,
        };
    },
});
