import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { createApp } from 'vue';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Autoplay } from 'swiper';
import * as components from './components/index';
import UiDropdown from './components/ui/dropdown.vue';
import UiIcon from './components/ui/icon.vue';
import UiIntersect from './components/ui/intersect.vue';
import UiModal from './components/ui/modal.vue';
import useModal from './composables/modal';
import useNavigation from './composables/navigation';
gsap.registerPlugin(ScrollTrigger);
const App = createApp({
    components: {
        ...components,
    },
    setup() {
        const { setModal, modalName } = useModal();
        const { isMenuOpen, toggleMenu } = useNavigation();
        return {
            Autoplay,
            Navigation,
            Pagination,
            isMenuOpen,
            modalName,
            setModal,
            toggleMenu,
        };
    },
});
// Global properties
App.config.globalProperties.blankImage =
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
// Global components
App.component('UiDropdown', UiDropdown);
App.component('UiIcon', UiIcon);
App.component('UiIntersect', UiIntersect);
App.component('UiModal', UiModal);
App.component('Swiper', Swiper);
App.component('SwiperSlide', SwiperSlide);
App.mount('#app');
