import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { defineComponent, onMounted, ref } from 'vue';
gsap.registerPlugin(ScrollTrigger);
export default defineComponent({
    setup() {
        const background = ref('transparent');
        onMounted(() => {
            document.querySelectorAll('[data-default-bg]').forEach((item) => {
                ScrollTrigger.create({
                    trigger: item,
                    start: 'top center',
                    end: 'bottom 40%+=100px',
                    onToggle: (self) => {
                        if (self.isActive) {
                            background.value = 'default';
                        }
                    },
                });
            });
            document.querySelectorAll('[data-transparent-bg]').forEach((item) => {
                ScrollTrigger.create({
                    trigger: item,
                    start: 'top center',
                    end: 'bottom 40%+=100px',
                    onToggle: (self) => {
                        if (self.isActive) {
                            background.value = 'transparent';
                        }
                    },
                });
            });
            document.querySelectorAll('[data-light-blue-bg]').forEach((item) => {
                ScrollTrigger.create({
                    trigger: item,
                    start: 'top center',
                    end: 'bottom 40%+=100px',
                    onToggle: (self) => {
                        if (self.isActive) {
                            background.value = 'light-blue';
                        }
                    },
                });
            });
            document.querySelectorAll('[data-orange-bg]').forEach((item) => {
                ScrollTrigger.create({
                    trigger: item,
                    start: 'top center',
                    end: 'bottom 40%+=100px',
                    onToggle: (self) => {
                        if (self.isActive) {
                            background.value = 'orange';
                        }
                    },
                });
            });
        });
        return {
            background,
        };
    },
});
