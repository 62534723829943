import { ref } from "vue";
const modalName = ref("");
const useModal = () => {
    const setModal = (name) => {
        modalName.value = name;
    };
    return {
        setModal,
        modalName,
    };
};
export default useModal;
