import { render } from "./icon.vue?vue&type=template&id=81655ace&scoped=true"
import script from "./icon.vue?vue&type=script&lang=ts"
export * from "./icon.vue?vue&type=script&lang=ts"

import "./icon.vue?vue&type=style&index=0&id=81655ace&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-81655ace"
/* hot reload */
if (module.hot) {
  script.__hmrId = "81655ace"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('81655ace', script)) {
    api.reload('81655ace', script)
  }
  
  module.hot.accept("./icon.vue?vue&type=template&id=81655ace&scoped=true", () => {
    api.rerender('81655ace', render)
  })

}

script.__file = "resources/js/components/ui/icon.vue"

export default script