import { render } from "./navigation-bar.vue?vue&type=template&id=323d82e5"
import script from "./navigation-bar.vue?vue&type=script&lang=ts"
export * from "./navigation-bar.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "323d82e5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('323d82e5', script)) {
    api.reload('323d82e5', script)
  }
  
  module.hot.accept("./navigation-bar.vue?vue&type=template&id=323d82e5", () => {
    api.rerender('323d82e5', render)
  })

}

script.__file = "resources/js/components/navigation-bar.vue"

export default script