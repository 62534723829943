import { defineComponent, computed } from 'vue';
export default defineComponent({
    name: 'UiIcon',
    props: {
        name: {
            required: true,
            type: String,
        },
    },
    setup(props) {
        const svgUrl = computed(() => `/app/themes/my-theme/dist/svg/sprite.symbol.svg#${props.name}`);
        return {
            svgUrl,
        };
    },
});
